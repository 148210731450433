<template>
	<v-dialog v-model="showInstrumentCategoryDialog" max-width="300" light>
		<template v-slot:activator="{ on: dialog }" v-if="icon">
			<v-tooltip bottom>
				<template v-slot:activator="{ on: tooltip }">
					<v-btn fab x-small depressed color="primary" v-on="{...dialog, ...tooltip}">
						<v-icon dark>mdi-plus</v-icon>
					</v-btn>
				</template>
				<span>Add Instrument Category</span>
			</v-tooltip>
		</template>
		<template v-slot:activator="{ on }" v-else>
			<v-btn color="primary" dark v-on="on">Add Instrument Category</v-btn>
		</template>
		<v-card>
			<v-form ref="instrumentCategoryForm" @submit.prevent="saveInstrumentCategory">
                <v-card-title>Category Name</v-card-title>
				<v-card-text>
					<v-text-field
						dense
						v-model="instrumentCategory.name"
						outlined
						autocomplete="off"
						:rules="[v => !!v]"
					></v-text-field>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" dark type="submit">Save</v-btn>
					<v-btn color="error" dark @click="closeInstrumentCategoryDialog">Close</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
    data: () => ({
        instrumentCategory: {
			id: null,
            name: null
		},
		showInstrumentCategoryDialog: false,
	}),

	props: {
		icon: false
	},

	methods: {
		closeInstrumentCategoryDialog() {
			this.resetInstrumentCategoryForm();
			setTimeout(() => {
				this.showInstrumentCategoryDialog = false;
			}, 100);
		},

		async saveInstrumentCategory() {
            let isValid = true;
            this.$refs.instrumentCategoryForm.validate();
            this.$refs.instrumentCategoryForm.inputs.forEach(input => {
                if(input.hasError) isValid = false;
            })

            if(!isValid) {
                return;
            }
		
			let isSuccess = await this.$store.dispatch('instrumentCategory/saveInstrumentCategory', this.instrumentCategory);
			if(isSuccess) {
				if(this.instrumentCategory.id == null) {
					this.resetInstrumentCategoryForm();
				} else {
					this.closeInstrumentCategoryDialog();
				}
			}
        },

		resetInstrumentCategoryForm() {
			this.$refs.instrumentCategoryForm.reset();
			Object.keys(this.instrumentCategory).forEach(key => {
				this.instrumentCategory[key] = null;
            })
            this.$refs.instrumentCategoryForm.resetValidation();
		}
	}
};
</script>

<style lang="scss" scoped>
.v-btn--fab.v-size--x-small {
    height: 26px;
    width: 29px;
}
</style>