<template>
	<v-dialog v-model="showInstrumentUnitDialog" max-width="300" light>
		<template v-slot:activator="{ on: dialog }" v-if="icon">
			<v-tooltip bottom>
				<template v-slot:activator="{ on: tooltip }">
					<v-btn fab x-small depressed color="primary" v-on="{...dialog, ...tooltip}">
						<v-icon dark>mdi-plus</v-icon>
					</v-btn>
				</template>
				<span>Add Instrument Unit</span>
			</v-tooltip>
		</template>
		<template v-slot:activator="{ on }" v-else>
			<v-btn color="primary" dark v-on="on">Add Instrument Unit</v-btn>
		</template>
		<v-card>
			<v-form ref="instrumentUnitForm" @submit.prevent="saveInstrumentUnit">
                <v-card-title>Unit Name</v-card-title>
				<v-card-text>
					<v-text-field
						dense
						v-model="instrumentUnit.name"
						outlined
						autocomplete="off"
						:rules="[v => !!v]"
					></v-text-field>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" dark type="submit">Save</v-btn>
					<v-btn color="error" dark @click="closeInstrumentUnitDialog">Close</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
    data: () => ({
        instrumentUnit: {
			id: null,
            name: null
		},
		showInstrumentUnitDialog: false,
	}),

	props: {
		icon: false
	},

	methods: {
		closeInstrumentUnitDialog() {
			this.resetInstrumentUnitForm();
			setTimeout(() => {
				this.showInstrumentUnitDialog = false;
			}, 100);
		},

		async saveInstrumentUnit() {
            let isValid = true;
            this.$refs.instrumentUnitForm.validate();
            this.$refs.instrumentUnitForm.inputs.forEach(input => {
                if(input.hasError) isValid = false;
            })

            if(!isValid) {
                return;
            }
		
			let isSuccess = await this.$store.dispatch('instrumentUnit/saveInstrumentUnit', this.instrumentUnit);
			if(isSuccess) {
				if(this.instrumentUnit.id == null) {
					this.resetInstrumentUnitForm();
				} else {
					this.closeInstrumentUnitDialog();
				}
			}
        },

		resetInstrumentUnitForm() {
			this.$refs.instrumentUnitForm.reset();
			Object.keys(this.instrumentUnit).forEach(key => {
				this.instrumentUnit[key] = null;
            })
            this.$refs.instrumentUnitForm.resetValidation();
		}
	}
};
</script>

<style lang="scss" scoped>
.v-btn--fab.v-size--x-small {
    height: 26px;
    width: 29px;
}
</style>