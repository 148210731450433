<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form ref="instrumentForm" @submit.prevent="saveInstrument" class="custom-form">
                    <v-card :elevation="1" color="blue lighten-5">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-toolbar color="#546E7A" dark :elevation="1" height="30px">
                                    <v-toolbar-title class="subtitle-2">Instrument Entry</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text class="py-0 mt-1">
                                    <v-row dense justify="center">
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Inst. ID</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        readonly
                                                        :value="$store.getters['instrument/newCode']"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Category</v-col>
                                                <v-col cols="8" class="pr-1">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        @focus="$store.dispatch('instrumentCategory/getInstrumentCategories')"
                                                        :loading="$store.getters['instrument/loading']"
                                                        :items="$store.getters['instrumentCategory/instrumentCategories']"
                                                        item-text="name"
                                                        item-value="id"
                                                        v-model="selectedCategory"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                                <v-col cols="1">
                                                    <instrument-category :icon="true"></instrument-category>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Inst. Name</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        autocomplete="off"
                                                        v-model.trim="instrument.name"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Inst. Unit</v-col>
                                                <v-col cols="8" class="pr-1">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        @focus="$store.dispatch('instrumentUnit/getInstrumentUnits')"
                                                        :loading="$store.getters['instrumentUnit/loading']"
                                                        :items="$store.getters['instrumentUnit/instrumentUnits']"
                                                        item-text="name"
                                                        item-value="id"
                                                        v-model="selectedUnit"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                                <v-col cols="1">
                                                    <instrument-unit :icon="true"></instrument-unit>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="5">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4" class="text-right pr-3">VAT</v-col>
                                                <v-col cols="7">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.trim="instrument.vat"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4" class="text-right pr-3">Re-order level</v-col>
                                                <v-col cols="7">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.trim="instrument.re_order_level"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4" class="text-right pr-3">Purchase Rate</v-col>
                                                <v-col cols="7">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.trim="instrument.purchase_rate"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="4"></v-col>
                                                <v-col cols="7">
                                                    <v-row no-gutters>
                                                         <v-col cols="6" class="pr-1">
                                                            <v-btn @click="resetForm" height="30px" dark block color="deep-orange">Clear</v-btn>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-btn type="submit" :loading="waitingForSave" height="30px" dark block color="light-blue darken-2">Save</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense class="mt-1">
            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="transactionHeaders"
                    :loading="$store.getters['instrument/loading']"
                    :items="$store.getters['instrument/instruments']"
                    :search="searchInstruments"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Instrument List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Instruments"
                                    append-icon="mdi-magnify"
                                    style="width:300px;"
                                    v-model="searchInstruments"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="editInstrument(item)" color="primary" v-on="on">mdi-circle-edit-outline</v-icon>
							</template>
							<span>Edit</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="openDeleteDialog(item.id)" color="error" v-on="on" >mdi-delete-circle-outline</v-icon>
							</template>
							<span>Delete</span>
						</v-tooltip>
					</template>
                </v-data-table>
            </v-col>
        </v-row>

        <delete-confirm ref="deleteDialog" @confirm="deleteInstrument"></delete-confirm>

    </v-container>
</template>

<script>
import DeleteConfirm from '@/components/Confirm'
import InstrumentCategory from '@/components/InstrumentCategory'
import InstrumentUnit from '@/components/InstrumentUnit'

export default {
    name: 'Instrument',
    components: {
        DeleteConfirm,
        InstrumentCategory,
        InstrumentUnit,
    },
    data: ()=> ({
        transactionHeaders: [
            { text: 'Inst. ID', value: 'code' },
            { text: 'Inst. Name', value: 'name' },
            { text: 'Category', value: 'instrument_category.name' },,
            { text: 'Purchase Price', value: 'purchase_rate' },
            { text: 'VAT', value: 'vat' },
            { text: 'Unit', value: 'instrument_unit.name' },
            { text: 'Action', value: 'action' },
        ],
        searchInstruments: '',
        selectedCategory: null,
        selectedUnit: null,
        instrument: {
            name: '',
            vat: 0,
            re_order_level: 0,
            purchase_rate: 0,
        },
        waitingForSave: false,
        instrumentEditId: null,
        instrumentDeleteId: null,
    }),
    created() {
        this.$store.dispatch('instrument/getInstrumentCode')
        this.$store.dispatch('instrument/getInstruments')
    },
    methods: {
        async saveInstrument() {
            if (!this.$refs.instrumentForm.validate()) return
            this.waitingForSave = true
            this.instrument.instrument_category_id = this.selectedCategory.id
            this.instrument.instrument_unit_id = this.selectedUnit.id
            if (this.instrumentEditId == null) {
                let res = await this.$store.dispatch('instrument/saveInstrument', {
                    url: 'add-instrument',
                    data: this.instrument
                })
                if (res) this.resetForm()
            } else {
                let res = await this.$store.dispatch('instrument/saveInstrument', {
                    url: 'update-instrument',
                    data: {...this.instrument, id: this.instrumentEditId}
                })
                if (res) this.resetForm()
            }
            this.waitingForSave = false
        },

        editInstrument(item) {
            this.instrumentEditId = item.id
            this.selectedCategory = item.instrument_category
            this.selectedUnit = item.instrument_unit
            Object.keys(this.instrument).map(k => this.instrument[k] = item[k])
        },

        openDeleteDialog(id) {
            this.instrumentDeleteId = id
            this.$refs.deleteDialog.dialog = true
        },

        deleteInstrument() {
            this.$store.dispatch('instrument/deleteInstrument', {id: this.instrumentDeleteId})
            this.$refs.deleteDialog.dialog = false
            this.instrumentDeleteId = null
        },
        
        resetForm() {
            this.selectedCategory = null
            Object.keys(this.instrument).map(k => this.instrument[k] = 0)
            this.instrument.name = ''
            this.instrumentEditId = null
            this.$refs.instrumentForm.resetValidation()
        }
    }
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px !important;
    }
    .v-icon.v-icon[data-v-1f38b4e5] {
        font-size: 14px !important;
        top: 0px !important; 
    }
    .v-messages {
        flex: 1 1 auto;
        font-size: 12px;
        min-height: 0px !important;
        max-height: 0px!important;
        min-width: 1px;
        position: relative;
    }
</style>